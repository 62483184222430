import "../nav-style.css"
import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar() {
    return <div className='d-flex flex-row justify-content-between nav-bar-border'>
        <nav className="d-flex flex-row align-items-center w-100 nav h6">
            <ul className="w-100 d-flex">
                <CustomLink to="/our-story">Our Story</CustomLink>
                <CustomLink to="/venue">Venue</CustomLink>
                <CustomLink to="/itinerary">Itinerary</CustomLink>
                <CustomLink to="/wedding-party">Wedding Party</CustomLink>
                <CustomLink to="/faq">FAQ</CustomLink>
                <CustomLink to="/upload">Upload</CustomLink>
            </ul>
        </nav>
    </div>
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end:true})
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to}>{children}</Link>
        </li>
    )
}