import React, { useEffect, useState } from "react";

export default function App() {
  const urlBase = "https://thumbnails-photos.amazon.com/v1/thumbnail/";
  const urlParams = "?ownerId=A2RV2IXVJY0NL3&viewBox=600&groupShareToken=qVKHuw_ESLO-raAEt_kXxw.v_bqF0rCCIil7TeQX2bixw";
  
  const [images, setImages] = useState([]); // useState for managing images

  // Helper function to shuffle an array
  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }
  
  useEffect(() => {
    getImages(); // Fetch images on component mount
  }, []);

  async function getImages() {
    try {
      const response = await fetch(
        'https://www.amazon.com/drive/v1/search/groups/qVKHuw_ESLO-raAEt_kXxw?asset=ALL&filters=type:(PHOTOS OR VIDEOS)&limit=200&lowResThumbnail=true&searchContext=customer&tempLink=false&groupByForTime=month&groupShareToken=qVKHuw_ESLO-raAEt_kXxw.v_bqF0rCCIil7TeQX2bixw&resourceVersion=V2&ContentType=JSON',
        {
          method: 'GET',
          headers: {
            "Accept": "application/json",
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = await response.json();
      // Shuffle and slice to get 12 random images
      const shuffledImages = shuffleArray(responseData.data).slice(0, 12);
      const fetchedImages = shuffledImages.map((item) => `${urlBase}${item.id}${urlParams}`);
      setImages(fetchedImages); // Update state with fetched images
    } catch (err) {
      console.log("Fetch error: " + err);
    }
  }

  return (
    <div>
      <div>
      <div className="upload-container">
        <p className="mb-3">Share your photos with us through the link below and browse the gallery of what our guests have already submitted.</p>
        <a href="https://www.amazon.com/photolabs/gather/a6c940c4-8714-055f-7623-edc3341d1940?ref_=aph_eventiOS" className="upload-button">
          + Upload Photos
        </a>
      </div>
      </div>
      <div className="image-grid">
        {images.length > 0 ? (
          images.map((src, index) => (
            <img key={index} src={src} alt={`Image ${index}`} className="grid-image" />
          ))
        ) : (
          <div className="loading-container">
            <p>Loading images...</p>
          </div>
        )}
      </div>
    </div>
  );
}