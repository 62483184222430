import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import OurStory from './components/OurStory';
import Venue from './components/Venue';
import LandingPage from './components/LandingPage';
import { Route, Routes } from "react-router-dom"
import WeddingParty from './components/WeddingParty';
import Itinerary from './components/Itinerary';
import Gallery from './components/Gallery';
import Song from './components/Song';
import RSVP from './components/RSVP';
import FAQ from './components/FAQ';
import Girls from './components/Girls';
import CountdownWeeks from './components/CountdownWeeks';
import VIP from './components/VIP';
import WeddingPartyResponsibilities from './components/WeddingPartyResponsibilities';
import Timeline from './components/Timeline';
import Thursday from './components/timeline-days/Thursday';
import Friday from './components/timeline-days/Friday';
import Saturday from './components/timeline-days/Saturday';
import Sunday from './components/timeline-days/Sunday';
import { Link } from "react-router-dom";
import Upload from './components/Upload';

const weddingDate = '2024-10-19'

function App() {
  var easterEgg = 
 ` "Real darkness has love for a face."
  #+++#**#***#*********##******#*************#########+=+##**##*#*######*+###############*#*##*#####*#
  ++####*******#****#***#*****##***********####*##*##++#####**++==*+#=#%+##=#+*############*##*#***###
  +#***#**********#***#*****#*#***********#*#######+=+*##*+=+==*=####*=#+*==+=#*##########**###*##*#*#
  ###***#****************************#***####*####++#+++=+++==+=++*+#++*+=++=++=+#####*##*#*#*####*#**
  #***#***#****#***#*****##**********###########+==++++++==++++===+=*=+++=========#####*###*######****
  #*********#**********#*************##*###*+=++=+++++++=++==++=++=++==+========++#*#**####*###*****#*
  ********#*********************#***###*++++=+=+======+=++=++#%%###%%#*=======+====*##*#*#*##*******##
  ***#****#***#*****#*************#*======++==++#%%###%%*=######%%%%%%%%%%=+====+===+##**##**#***##*#*
  ****###*********#*************+=====++++++=###%#*%%#%%##%%#%*#*#*#####%%+%*========##**####**#**#*##
  ***#***********************++=+===++=++=*#######%%%%%%%#***###%##%#%%%##%%*%=++=====**##**#****###**
  ***#***#*****##**********#+++=++=++====%%%%%%%%%%%#*##%######%%#%%####%%#%%=%========********##***##
  ***********#************++++++++=++++%%%%%%%%####%#%%##%%%%%##%%%#%*###%#%%%*%=====*++#****##*#***#+
  *******#***#********#***+++++=*%%*+*%######%%%%%%%%%%%%%#%%%%#%%%##%##%%#%%%#%+%**%%%=*#*#**##***==#
  ********##*************=+++#%++=+=%%%#####*%#%%%%%%%%%%#%%%%%%#%%%#%%#%%#%%%%%%=======+#**#*#***==##
  ******#************##++++*+=++++*##%%%#%#%%%%#%%#%%%#%%%%%%%%%%%%%#%%%%%%%%%%#%%%=#*#@#*######==*#**
  **#***#********#***+==+=+==++===+%%%%%%%%#%%%%%#%%%%%##%%#%%%%%%%%%#%%%%%%%#%%%%%@%*=++#####==+*****
  ***##**********#*++===+++=++==%==+%%%%%%%%%%%#%%%%#%%%%#%**%%#@%%%%@%%%%%%%%%*#**##%+==####==**##***
  *#************+=++=+++=+%%=+=+=#%########%%%#%%%%%%#**#******%%@@@%@@%@%%@%%@@%@%#%%%%=##==*********
  ***********++++++++++%==+%%%%%%%#%%%%%%##%#%%#%%%@##*+++++******@##@@%%@@%@%@@@%#%%%%++==+#***#*****
  *******+++++++++++++=%==+==%%%%####%####%%%%%%%%%**+=======++******@#**@#@*%@@%#%%%%%==+#********##*
  *#*****#+=++++=++++*+=+@%=%+##%%%%%%%%#%%%%%%@*#*+===========+++++*%%@#**#*#%%%#%#%=+==************#
  *********===+=+=+%++=%=+=%%%%%#%%%%%%%@#%#%****+*+=========+++*+++*%+%+%@%**@@%@%@=+===*************
  *****####*#+++=++++++++=++%%%%%%%%%%%@%%%*****++++++=======*+*+++*++%+%+*%####%@%@+==++******#******
  **#**#*****#=+++++=+=+=#%###%%%%%%%%#@@%@***+++++*++++====++*+**+++++%%**%*###%%*#+#*++****#********
  ****##*******=+++++++==%%%%#%%%%%%%%@%%%****++++*++*++=====%*+*+*+*+*#%#*#*###%%++@%*++**#***+******
  **#********##==++=++=+=%#%%%%%#%%@%%##******++++++++========*#++++++*@%*#**####%@+%@+++##**+********
  *******##**#+++==+==+==%%%%%%##%%%%#*#***++++**++===++=+++**#++#%***%%****###%%%#%@#==+*##**********
  *****#***#==##+=+=+===+%#%#%%#%%%%%#*++*++++++*+*+++++++==+***@***@*******###@%+#+++++=*************
  *#****#*++=###*=+=====%#%%##%%%%%%#*******%%********+*++=+=++*+************###%++++==+=*************
  ****#*#=+####*#+======#%%%%%%%%*%**++****#****#%@@@@%#**++**++**@#*********##%%%==+==+=*****+*+***+*
  *****=+*####*#*+=====*%##%%%#%%%%#**************##@%@@@@@@*+*++#@+*********###@%=+=====***+*********
  ****==###***#**======*###%%##%%%%%%**++**%##*#######%%@@@@@%**#***%*%**%#***%%@*====++++************
  **=+#*##*######+==+==*%####%%%%%%**#%+*+###@@@%@%@@@@@@@@@@%##**#%@@@@@@@@%%%@@+=+=++++++=**********
  +=+##*##*##*###++=====%###%%%%%%%%*%**++*#%###@@%##%%@%@%*##**##@@@@@@@*#%##@@%=++++++++++++*******+
  +**###*#####*##+=+====%##%%%%%%%%#%**+++++#####%#@@@@@@++#****#@@@@@@@#%##@@@*++++++++++=+++********
  *#*##*##*###*##*==+=====*%%%%%#@%@@***+++++%@###@@@%*%+*******%@@%#*%#@@@@@@++++++++==+=+***********
  *#*####**###**#++========#%@%%%%@@#**#********%####****+*****#@@@##@@%%@#@@=+++++==+====************
  *#**####*#####+========+%%%@#*@%@@@**********+*++***#***@#####@@@#@@@@@@##@+++==+====+=***********++
  *#####**#*###=+===##%%%@%%@%@@@@@@#********#%*@#@@@%#%#@%%###%%@@@@@@@###@++=+====+==++******+=+*+==
  *###*#######+=======@%%@%@%@@@@@@@@******%%%@@%@@@%####%%%###%@%#*##*####@+====+==+++==******++==+=+
  ###*##**##**+=====#+%@@%@%%%#%@%@@@@@***%*%%@@@%@@@@@####=*##@@@@#**####@@==+==+++=====****+**======
  #####**+=+++=======%@%%%@@%#*%%@@@%%%%#%%@%%@%%%@%%@@@@##=%##%@%@@#####*@@@==++==+===++*****========
  =+================+==@%@@@%%%%%%@@@@%@%%@@@@@@@%@@@@%@###%%@@@%%@%@###%@@@#@%======++++***==========
  ==============+===+=++@#%@%@@@##@%@@%%%@%%%%%%%%%@@@@@@@@@@@@@@@@@@@#@@@@@@@====+==+================
  ============+=+===+*@@@*@@@@@@%%%%#%%@@%@@%@@%@@%@@%@%%@@%@@@@@@@@@@@@%@@@@*=+==+====+==========+===
  ===========+=+==*+%%@@@**%@@@@@%%@***##%@@@@@@@@%@@@%%@@@@@@@@@@@@@@@@@%%%%@=================+======
  ========+=====+#%%@%%@@***%@@%@@@@%#@@@%%@@@@@%%#*########@@@@@@@@@@@@@@%@%=========================
  ======++=+=+*#%%%%%%@@%****%#@@@@@@@@@%##@@%%%%%#%@@@%####@@@@@@@@@@@%%%@=================++========
  =+===+==++%*%%%%%%%%@@********%@@@@%@%@%@@%%%%%%#@@@@%%%@@%%%@@@@@@@@%@%%@=+=========+========+=====
  +===++=+*%%%%%%%%%%%@@*********%@@@@@@@@%%####@@%@%#####*@@%#%@@@@@@@@@%@@======+=======+===+=======
  ===++=%@%%%%%%%%%%%@@%*********#@@@@@@@@@%######%***#######%%@@%@@@@@%@%@======+=++=================
  +#%@%%%%%%%%%%%%%%%@@%**********#%%@@@@@@@%######****%***#%%@@@@@@@@%@@=======++=====+==============
  %%%%%@%%%%%%%%%%%%%@@#************%@@@@@@@@@%%%##%%%%%*@%%%@%@@@@@##@*+++=+=++++=====+==============
  %%%%@@%%%%%%%%%%%%%@@*************+%@@@@@@@@@%%%%%%%%%%%%@##*#@@@#==========+=======================
  %%%@@%%%%%%%%%%%%%@@@**************+%@@@@@@@@@@@@@@@@@@@#**##*#%%@%++==============================+
  %%@@%%%%%%%%%%%%%%@@@*****#*********+%%@@@@@@@@@@@@@#*#*###****%%%%@@++=========+======+=========+==
  %@@%%%%%%%%%%%%%%%@@%*************#**%%%%@@@@@@@@@@#****#*****##%%%%@@@+++=====++========+==========
  @@@%%%%%%%%%%%%%%%@@#***************@@@@@@@@@@%@@@*****#****#***%%%%%%%@@+++++=======+======++======
  @@#%%%%%%%%%%%%%%%@@**************@@@@@%%%%@@@@@@@****#*##*#***#+@%%%%%%%@@@++++===========++==+====
  @%#%%%%%%%%%%%%%%%@@***********@@@@@@@%%%%%%%@@%%@@@@@@#*#**#****#@%%%%%%%%%@@++=+=======+==========
  %%%%%%%%%%%%%%%%%%@*********@@@@@@@@@@@@%%%%@%%%%%%%@@@@@@@@#*****%@%%@%%%%@@@@@@+===============+==
  %%%%%%%%%%%%%%%%%%****+**@@@@@@@@@@@@%@@@@%@%%%%%%%%@@@@@@@@@@@***@@%%%%%%@@@@@%@@@==+==+=+=+=====+=
  %%%%%%%%%%%%%%%%%@***++*+#@@@@%@@@@@%@@%@@@@@@@%@%%%@@@@@@@@@@@@**#@%%%%%%@@@@@@%@##@++======+======
  %%%%%%%%%%%%%%%%%****++*#@@@@%%%%%%%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@***%%%%%%%%@@@@@%@@%%@**+===++=+===
  @%%%%%%%%%%%%%%%%*****%@@@@@@%%%%%%%%@@%@###%%%@@@@@@@@@@@@@@@@@@@#*@%@%%%%%%@@@@@@@@%%%@@%=========
  @@@@@%%%%%%%%%%@#*#+#@@@@@@@@%%%%@%%%@@@%%%%%#%#@@%@@%##@@@@@@@@@@@#*%@%%%#@%%%@@@@@@@%%%%@@@=======
  @@@@@@@%%%%%%%%@@#%@@@@@@@@@@%%%%%%%%@@@%@@%@##%%%@#%*#*@@@@@@@@@@@@**%%@@@@%%%%@@@@@%@%%%%%#@=+====
  @@@@@@@@@%%%%@@@@%@@@@@@@@@@@%%%%%%%%%@@@@@@@@%##%@***##%@@@@@@@@@@@@#%@%%%%%%%%%@@@@@%@%%%%%%%@==++
  @@@@@@@@@@%%@@@@@@@@@@@@@@@@@**%%%%%%%@@@@@%%#%%%@%***%#%@@@@@%@@@@@@@%@%@%%%%%%%@@@@@@%@%%%%%#@@=++
  %@@@@@@@@@@%%@@@@%%@@@@@@@@@@**%%%%%%%%%@@%%%%#%%%%#****%@@@@@@@@@@@@@@@%%@%%%%%%@@@@@@@@%%%%%%@@%++
  @%@%@@%@@@@@@%%%%%@@@@@@@@@@@***#%%%%%%%@@%%@@@%%%**#**%%@@@@@@%@@@@@@@%@@@@%%@%%@@@@@@@@@@@@#%@@+++
  @@%%%%%%%%@@%%%%%%@@@@@@@@@@@%*****%%%%%%@@@@@@%@*#****%%#%@@@@%%%%%%%%%@%@@@%@@@@@@@@@@@@@@@@@@@==+
  @@@%@%%%%%%@%%%@%@@%%@@@@@@@@@******%%*#%@@@%%%@@****#*%%%@@@@@@%%%%%@%%%%@@@@@@@@@@@@@@@@@@@@@@@=+=
  @@@@%@@%%%%%%%%%%@%@%@@@@@@@@@*%***%%%*+#@@%%%%%%%****#%%%@@@@@@%%%%%%@%%@@@@%%@@@@@@@@@@@@@@@@@@=++
  @@@@@@@@@@@@@@@@%%@%%@@@@@@@@@@#*#%#%%%+@@%%@%%@%%****%%#%@@@@@@%%@%%%%%@%%%%@%%@@@@@@@@@@@@@@@@@@++`
  
  console.clear();
  console.log(easterEgg);
  return (
    <div className="App">
      <header className="App-header mb-4">
      <div className="Title-Header">
        <Link className="" to="/"><div className='Header-Font Header'> AJ & Cassie</div></Link>
        <div className='Subheader-Small-Font Aerotis-Font mb-2'> October 19, 2024 </div>
        <CountdownWeeks date = {weddingDate} />
      </div>
        <div className="w-100">
          <Navbar />
        </div> 
      </header>
      <div className="App-body">
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/our-story" element={<OurStory />}></Route>
          <Route path="/venue" element={<Venue />}></Route>
          <Route path="/itinerary" element={<Itinerary />}></Route>
          <Route path="/wedding-party" element={<WeddingParty />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/song" element={<Song />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/dresses" element={<Girls />}></Route>
          <Route path="/rsvp" element={<RSVP />}></Route>
          <Route path="/vip" element={<VIP />}></Route>WeddingPartyResponsibilities
          <Route path="/responsibilities" element={<WeddingPartyResponsibilities />}></Route>
          <Route path="/timeline" element={<Timeline />}></Route>
          <Route path="/thursday" element={<Thursday />}></Route>
          <Route path="/friday" element={<Friday />}></Route>
          <Route path="/saturday" element={<Saturday />}></Route>
          <Route path="/sunday" element={<Sunday />}></Route>
          <Route path="/upload" element={<Upload />}></Route>
        </Routes>
      </div>
      <footer className="Footer"></footer>
    </div>
  );
}

export default App;
