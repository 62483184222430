import sm_inn from '../assets/sm-inn.jpg'

export default function Venue() {
    return <div>
        <div className="d-flex justify-content-center align-items-center venue">
            <div className="large-image-container">
                <h1 className="display-6">The South Mountain Inn</h1>
                <p>
                    143 North Galen Hall Road <br/>
                    Wernersville, Pennsylvania 19565 
                </p>
                <a target="_blank" href="https://thesouthmountaininn.com/"><img src={sm_inn} alt="inn"></img></a>
            </div>
        </div>
        <div className="accommodations">
            <h2>Guest Accommodations</h2>
            <h2 className="display-6">Hampton Inn Wyomissing</h2>
            <p>
                1800 Papermill Rd. <br/>
                Wyomissing, Pennsylvania 19610
            </p>
        </div>
    </div>
}